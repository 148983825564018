<template>
  <div class="d-flex flex-row">
    <div
      class="flex-column flex-lg-row-auto w-100 w-xl-250px mb-5 me-lg-10"
    >
      <div class="card card-custom">
        <div class="card-body pt-4">
          <div
			  id="#kt_header_menu"
			  class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
			  data-kt-menu="true"
			>
          <div
            class="menu menu-rounded menu-column menu-title-gray-700 menu-state-title-primary menu-active-bg-light-primary fw-bold"
          >
            <div class="menu-item mb-1">
				<router-link
				  v-slot="{ href, navigate, isActive, isExactActive }"
				  :to="'/template/component/'+uid+'/general'"
				>
				  <a
					:class="[
					  isActive && 'active',
					  isExactActive && 'active'
					]"
					:href="href"
					class="menu-link"
					@click="navigate"
				  >
					
					<span class="menu-title">General</span>
				  </a>
				</router-link>
            </div>
			
			<div class="menu-item mb-1">
				<router-link
				  v-slot="{ href, navigate, isActive, isExactActive }"
				  :to="'/template/component/'+uid+'/columns'"
				>
				  <a
					:class="[
					  isActive && 'active',
					  isExactActive && 'active'
					]"
					:href="href"
					class="menu-link"
					@click="navigate"
				  >
					
					<span class="menu-title">Columns</span>
				  </a>
				</router-link>
            </div>
			
			<div class="menu-item mb-1">
				<router-link
				  v-slot="{ href, navigate, isActive, isExactActive }"
				  :to="'/template/component/'+uid+'/actions'"
				>
				  <a
					:class="[
					  isActive && 'active',
					  isExactActive && 'active'
					]"
					:href="href"
					class="menu-link"
					@click="navigate"
				  >
					
					<span class="menu-title">Actions</span>
				  </a>
				</router-link>
            </div>
			
			<div class="menu-item mb-1">
				<router-link
				  v-slot="{ href, navigate, isActive, isExactActive }"
				  :to="'/template/component/'+uid+'/fields'"
				>
				  <a
					:class="[
					  isActive && 'active',
					  isExactActive && 'active'
					]"
					:href="href"
					class="menu-link"
					@click="navigate"
				  >
					
					<span class="menu-title">Fields</span>
				  </a>
				</router-link>
            </div>
			
			<div class="menu-item mb-1">
				<router-link
				  v-slot="{ href, navigate, isActive, isExactActive }"
				  :to="'/template/component/'+uid+'/filters'"
				>
				  <a
					:class="[
					  isActive && 'active',
					  isExactActive && 'active'
					]"
					:href="href"
					class="menu-link"
					@click="navigate"
				  >
					
					<span class="menu-title">Filters</span>
				  </a>
				</router-link>
            </div>
			
          </div>
        </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
		<transition name="fade-in-up">
			<router-view />
		</transition>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>

export default {
  data() {
    return { 
      tabIndex: 0,
	  uid: this.$route.params.uid,
    };
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      target.classList.add("active");
    }
  }
};
</script>